//actions
const UPDATE_FREIGHT_CHARGES = 'UPDATE_FREIGHT_CHARGES ';
const UPDATE_CUSTOM_FREIGHT_CHARGES = 'UPDATE_CUSTOM_FREIGHT_CHARGES';
const RESET_CHARGES = 'RESET_CONTAINER_DETAIL';
const RESET_ADDITIONAL_CHARGES = 'RESET_ADDITIONAL_CHARGES';

export function updateFreightCharges(data) {
  return { type: UPDATE_FREIGHT_CHARGES, payload: data };
}
export function updateCustomFreightCharges(data) {
  return { type: UPDATE_CUSTOM_FREIGHT_CHARGES, payload: data };
}
export function resetCharges() {
  return { type: RESET_CHARGES, payload: [] };
}
export function resetAdditionalCharges() {
  return { type: RESET_ADDITIONAL_CHARGES, payload: [] };
}
//reducer
export default function reducer(
  state = {
    freightCharges: [],
    customFreightCharges: []
  },
  action
) {
  switch (action.type) {
    case UPDATE_FREIGHT_CHARGES:
      return {
        ...state,
        freightCharges: action.payload
      };

    case UPDATE_CUSTOM_FREIGHT_CHARGES:
      return {
        ...state,
        customFreightCharges: action.payload
      };
    case RESET_ADDITIONAL_CHARGES:
      return {
        ...state,
        customFreightCharges: []
      };

    case RESET_CHARGES:
      return {
        freightCharges: [],
        customFreightCharges: []
      };

    default:
      return state;
  }
}

export function freightChargeAddRedux(data) {
  return (dispatch) => {
    dispatch(updateFreightCharges(data));
  };
}

export function freightChargeCustomAddRedux(data) {
  return (dispatch) => {
    dispatch(updateCustomFreightCharges(data));
  };
}
export function resetChargePricing() {
  return (dispatch) => {
    dispatch(resetCharges());
  };
}

export function resetAdditionalChargePricing() {
  return (dispatch) => {
    dispatch(resetAdditionalCharges());
  };
}
