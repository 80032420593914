import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation/loading.json';

const LoadingAnimation = (props) => {
  const { spinner, message } = props;

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const styleSetting = spinner
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 'inherit'
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh'
      };

  const lottieSize = {
    height: !spinner ? 200 : 50,
    width: !spinner ? 200 : 50
  };

  return (
    <div style={styleSetting}>
      <Lottie options={lottieOptions} height={lottieSize.height} width={lottieSize.width} />
      {message && (
        <p
          className="text-center"
          style={{ flex: 'initial', color: '#0065a0', fontWeight: 'bold' }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default LoadingAnimation;
